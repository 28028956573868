import { createContext, useEffect, useReducer } from 'react';
import { AppAction, AppReducer, CashbackConfig, MerchantHeaderDetails, SmallPopUpCopy, getInitialState } from './app.reducer';
import useMerchantHeaderDetails from './page/util/use-merchant-header-details.hook'
import { PromoService } from './service/promo.service'
import { useUrlParamParser } from './page/util/url-param-parse.hook'
import { useLocation } from 'react-router-dom'
import { getLocalStorageKey, setLocalStorageKey } from './utils/local-stoarge.helper'
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'
import { UnleashEnum } from './enum/unleash-enum'

export const AppContext = createContext<any>(getInitialState({}));

export const AppStateProvider = ({ children }: any) => {
    const transactionParamModel = useUrlParamParser();
    const { checkoutId, orderValue } = transactionParamModel;
    const { isLoaded, merchantHeaderTitle, merchantLogo, showMerchantHeader } = useMerchantHeaderDetails();
    const location = useLocation();
    const redeemCashback = useFlag(UnleashEnum.CASHBACK_ROLLOUT);
    const { flagsReady } = useFlagsStatus();

    const isRedeemCashBackEnabled = flagsReady && redeemCashback;

    const [state, dispatch] = useReducer(
        AppReducer,
        getInitialState({
            isLoaded,
            merchantHeaderTitle,
            merchantLogo,
            showMerchantHeader
        })
    );

    const {
        cashbackConfig: { cashBackDetailsLoaded = false, applicableCashBack = 0, cashbackAmtApplied = 0 }
    } = state;

    const isCashBackUsed = isRedeemCashBackEnabled && applicableCashBack > 0 && cashbackAmtApplied === applicableCashBack;

    const updatePopUpBlocker = (popUpBlocked: boolean, smallView: boolean = false, smallPopUpCopy: SmallPopUpCopy) => {
        dispatch({
            type: AppAction.UPDATE_POP_UP_BLOCKED,
            payload: {
                popUpBlocked: popUpBlocked,
                smallView: smallView,
                smallPopUpCopy: smallPopUpCopy
            }
        });
    };

    const updateRetryFunction = (retry: () => void) => {
        dispatch({
            type: AppAction.UPDATE_RE_TRY_FUNCTION,
            payload: {
                retry: retry
            }
        });
    };

    const retryPopUpBlocker = () => {
        dispatch({
            type: AppAction.UPDATE_BLOCK_COUNT,
            payload: {}
        });
        state.retry();
    };

    const updateViewType = (viewType: string) => {
        dispatch({
            type: AppAction.UPDATE_VIEW_TYPE,
            payload: { viewType }
        });
    };

    const updateIsIframe = (isIframe: boolean) => {
        dispatch({
            type: AppAction.UPDATE_IS_IFRAME,
            payload: {
                isIframe: isIframe
            }
        });
    };

    const updateMerchantHeaderDetails = (merchantHeaderDetails: MerchantHeaderDetails) => {
        dispatch({
            type: AppAction.UPDATE_MERCHANT_HEADER_DETAILS,
            payload: {
                merchantHeaderDetails
            }
        });
    };
    
    const updateCashBackConfig = (cashbackConfig: Partial<CashbackConfig>) => {
        dispatch({
            type: AppAction.UPDATE_CASHBACK_CONFIG,
            payload: {
                cashbackConfig
            }
        });
    };

    const fetchCashbackDetails = async () => {
        if (['/otp', '/otp-bypass', '/otp-bypass-confirm', '/login', '/'].includes(location.pathname)) {
            return;
        }
        try {
            const { data: cashback } = await PromoService.getCashBackDetails(checkoutId!); // availableCashback
            if (cashback.applicableCashBack !== undefined) {
                updateCashBackConfig({
                    ...cashback,
                    cashBackDetailsLoaded: true,
                });
                setLocalStorageKey(`${checkoutId}_cashback_applied`, cashback.cashbackAmtApplied);
            }
        } catch (error) {}
    };

    const divideCashBack = (dp: number, emi: number = 0, tenure: number = 1) => {
        const appliedCashback = isCashBackUsed ? cashbackAmtApplied : 0
        const dpRate = dp / parseInt(orderValue);
        const dpCashback = Math.floor(appliedCashback * dpRate);
        const emiCashback = Math.floor((appliedCashback - dpCashback) / tenure);
        const dpCashbackRounded = Math.floor(applicableCashBack * dpRate);
        const emiCashbackRounded = Math.floor((applicableCashBack - dpCashbackRounded) / tenure);
        return {
            finalDp: dp - dpCashback,
            finalEmi: emi - emiCashback,
            totalCashBack: dpCashback + (emiCashback * tenure),
            totalCashbackRounded: dpCashbackRounded + (emiCashbackRounded * tenure)
        };
    };

    useEffect(() => {
        if (isRedeemCashBackEnabled && !cashBackDetailsLoaded) {
            fetchCashbackDetails();
        }
    }, [location, flagsReady]);

    useEffect(() => {
        updateCashBackConfig({ cashBackDetailsLoaded: flagsReady && !redeemCashback });
    }, [flagsReady]);

    const value = {
        popUpBlocked: state.popUpBlocked,
        blockCount: state.blockCount,
        smallView: state.smallView,
        smallPopUpCopy: state.smallPopUpCopy,
        webView: state.webView,
        isIframe: state.isIframe,
        viewType: state.viewType,
        headerDetails: state.merchantHeaderDetails,
        cashbackConfig: state.cashbackConfig,
        isCashBackUsed,
        isRedeemCashBackEnabled,
        updatePopUpBlocker,
        updateRetryFunction,
        retryPopUpBlocker,
        updateIsIframe,
        updateViewType,
        updateMerchantHeaderDetails,
        updateCashBackConfig,
        divideCashBack
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
