import { useEffect, useState } from 'react';
import { UserService } from '../../service/user.service';
import { useUrlParamParser } from '../util/url-param-parse.hook';
import { registerUser, authenticateUser, processResp } from '../../modules/ashield';
import { useRedirectionHelper } from '../util/use-redirection.hook';
import { CommonLoaderComponent } from '../../component/loader/common-loader.component'
import { LogsService } from '../../service/logs.service'
import { LogLevel } from '../../enum/enum'
import { AshieldService } from '../../service/ashield.service'
import OtpBypassConfirm from './otp-bypass-confirm'

const OtpBypass = () => {
    const [currentState, setCurrentState] = useState('otp-bypass');
    const transactionParamModel = useUrlParamParser();
    const { checkoutId, mobile, resp } = transactionParamModel;
    const { checkRedirection } = useRedirectionHelper();

    const goNext = async (data?: Record<string, boolean | string>) => {
        let id = transactionParamModel?.checkoutId ? transactionParamModel?.checkoutId : '';
        const res = await UserService.moveNext(id, data);
        checkRedirection(res.data);
    };

    const onOtpBypassFail = async (errorCode: string, errorMsg?: string) => {
        LogsService.log({
            level: LogLevel.ERROR,
            message: `Ashield verification failed for mobile ${mobile} cart ${checkoutId} - ${errorCode} ${errorMsg}`
        });
        goNext({ token: transactionParamModel.token as string, otpBypassFailed: true });
    };

    const onOtpBypassSuccess = async () => {
        try {
            await AshieldService.authorize(checkoutId as string);
            localStorage.setItem(`ashield_registered_${mobile}`, 'true');
            LogsService.log({
                level: LogLevel.INFO,
                message: `Ashield verification successful for cart - ${checkoutId}`
            });
            const {
                data: { tncAgreed }
            } = await UserService.isTncAgreed(checkoutId as string);
            if (tncAgreed) {
                goNext();
            } else {
                setCurrentState('otp-bypaas-confirm');
            }
        } catch (error) {
            onOtpBypassFail('', 'ASHIELD_VERIFICATION_UNSUCCESSFUL');
        }
    };


    const handleOtpBypass = async () => {
        const {
            data: { signature }
        } = await AshieldService.getSignature(checkoutId as string);
        const isAshieldRegistered = localStorage.getItem(`ashield_registered_${mobile}`);
        const ashieldMid = process.env.REACT_APP_ASHIELD_MID;
        if (isAshieldRegistered) {
            authenticateUser(mobile, ashieldMid, checkoutId, signature, {}, onOtpBypassFail, onOtpBypassSuccess);
        } else {
            if (resp) {
                processResp(resp, onOtpBypassFail, onOtpBypassSuccess);
            } else {
                registerUser(mobile, ashieldMid, checkoutId, window.location.href, signature, {}, onOtpBypassFail);
            }
        }
    };

    useEffect(() => {
        handleOtpBypass();
    }, []);

    return (
        <div className="max-w-[374px] mx-auto relative flex">
            {currentState === 'otp-bypass' ? <CommonLoaderComponent /> : <OtpBypassConfirm />}
        </div>
    );
};

export default OtpBypass;
